































































































import { ExportAllItensUseCase, ImportaItensUseCase } from '@/usecases';
import { Component, Vue } from 'vue-property-decorator';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { uploadFilesToS3 } from '@/shareds/s3/files';

@Component
export default class DialogoDeCsvTabelaDePrecos extends Vue {
	mostra = false
	tab: null | number = null
	tabs = [
		{ action: 'Importar', icon: 'mdi-file-upload' },
		{ action:'Exportar', icon: 'mdi-file-download' },
	]
	exportAllItensUseCase = new ExportAllItensUseCase()
	importaItensUseCase = new ImportaItensUseCase()
	importando = false
	exportando = false
	arquivoContemCabecalho = false
	arquivoCsv: File[] = []
	tabelaNome: string | null = null
	tabelaId: string | null = null
	selecionado: boolean | null = null

	async mostrar(tabelaId?: string, tabelaNomeRecebido?: string){
		this.tabelaId = tabelaId ? tabelaId : null
		this.tabelaNome = tabelaNomeRecebido ? tabelaNomeRecebido : null
		
		this.mostra = true
	}

	cancelar() {
		this.importando = false
		this.mostra = false
		this.arquivoCsv = []
		this.arquivoContemCabecalho = false
	}

	async concluirImportacao() {
		if (this.arquivoCsv.length == 0) {
			return AlertModule.setError("É obrigatório selecionar um arquivo");
		}
		if (!this.tabelaId) {
			return;
		}

		try {
			this.importando = true;

			const informacoesDosArquivos = await uploadFilesToS3(
				this.arquivoCsv,
				`importacao-tabela-de-preco`,
			);
			const urlDoArquivo = informacoesDosArquivos.map(({ config }) => config.url)[0];

			if (!urlDoArquivo) {
				return AlertModule.setError("Ocorreu um erro interno, contate o suporte");
			}

			await this.importaItensUseCase.execute(
				this.tabelaId,
				this.arquivoContemCabecalho,
				urlDoArquivo,
			);

			AlertModule.setSuccess('Itens em processamento');
			this.$emit('concluiuImportacao');
		} catch (e) {
			this.importando = false;
			AlertModule.setError(e);
		} finally {
			this.importando = false;
			this.arquivoCsv = [];
			this.mostra = false;
			this.arquivoContemCabecalho = false;
		}
	}

	async exportarCsv() {
		if(!this.tabelaId) return
		try {
			AlertModule.setSuccess('O arquivo está sendo gerado. O processo pode levar alguns minutos.')
			const response: any = await this.exportAllItensUseCase.exportAllItens(this.tabelaId)
			const url = window.URL.createObjectURL(new Blob([response]));
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', `precos_${this.tabelaNome}.csv`)
			document.body.appendChild(link)
			link.click()
		} catch (e) {
			AlertModule.setError(e)
		} 
	}
}
