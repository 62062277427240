























































import SeletorDeLojas from '@/components/loja/SeletorDeLojas.vue'
import {
	FormLoja,
	LojaDoPdv,
	LojaTabelaPreco,
	TabelaDePreco,
	TipoDeCliente,
} from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import {
	FindLojaUseCase,
	UpdateTabelaDePrecoUseCase,
} from '@/usecases'
import Vue from 'vue'
import Component from 'vue-class-component'
import { Ref } from 'vue-property-decorator'

@Component({
	components: {
		SeletorDeLojas,
	},
})
export default class DialogoVincularLojaComTabelaDePreco extends Vue {
	@Ref() buscaDeProduto!: SeletorDeLojas
	mostra = false
	carregando = false
	loja: FormLoja[] = []
	listaLojaTabelaPreco: LojaTabelaPreco[] = []
	tabelaDePreco: TabelaDePreco | null = null
	tipoClienteDaTabela: TipoDeCliente | null = null

	tabelaDePrecoUseCase = new UpdateTabelaDePrecoUseCase()

	async salvarItem() {
		this.carregando = true
		if (!this.tabelaDePreco) return
		try {
			await this.tabelaDePrecoUseCase.updateTabelaPreco(
				this.listaLojaTabelaPreco,
				this.tabelaDePreco.id,
			)
		} catch (e) {
			AlertModule.setError(e)
		} finally {
			this.mostra = false	
			this.carregando = false
			this.listaLojaTabelaPreco = []
			AlertModule.setSuccess('Loja vinculada com sucesso')
			this.$emit('concluirVinculo')
		}
	}

	mostrar(tabela: TabelaDePreco, lojas: LojaDoPdv[]) {
		if (!tabela || !lojas) return
		this.mostra = true
		this.tabelaDePreco = tabela
	}

	async adicionarParametro(idLojaNova: string, indice: any) {
		
		const tabelaId  = this.tabelaDePreco != null ? this.tabelaDePreco.id : null
		let podeAdicionar: boolean | true = true
		
		const lojasDaLista = this.listaLojaTabelaPreco.length > 1 ? this.listaLojaTabelaPreco.filter(
			loja =>
				loja.id === idLojaNova,
		) : []

		if (lojasDaLista.length > 1) {
			AlertModule.setError(
				'Essa loja já está selecionada',
			)
			podeAdicionar = false
			this.removerTabelaDePrecoDaLoja(indice)
			return
		}
		
		const lojaSelecionada = await new FindLojaUseCase().findLojaById(idLojaNova)
		const lojasValidada = lojaSelecionada.configuracaoDaLoja.tabelasDePreco.filter(
			tabelaPreco =>
				tabelaPreco.tabelaDePreco.id === tabelaId,
		)
		if (lojasValidada.length > 0) {
			AlertModule.setError(
				'Essa loja já está vinculada com esta tabela',
			)
			this.removerTabelaDePrecoDaLoja(indice)
			podeAdicionar = false
		}

		if (podeAdicionar) {
			idLojaNova != null
				? (this.listaLojaTabelaPreco[indice].id = idLojaNova)
				: null
			this.tabelaDePreco != null
				? (this.listaLojaTabelaPreco[indice].tipoDeCliente = this.tabelaDePreco.tipoDeCliente)
				: null
		}
	}

	esconder() {
		this.mostra = false
		this.listaLojaTabelaPreco = []
	}

	get tabela() {
		return this.tabelaDePreco
	}

	get tabelasDePrecosLoja() {
		if (!this.listaLojaTabelaPreco) return []
		return this.listaLojaTabelaPreco
	}

	adicionarLoja() {
		this.listaLojaTabelaPreco.push({
			id: '',
			tipoDeCliente: null,
		})
	}

	removerTabelaDePrecoDaLoja(indice: number) {
		if (!this.listaLojaTabelaPreco) return
		this.listaLojaTabelaPreco.splice(indice, 1)
	}
}

