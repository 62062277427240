










































































































































import { FormItemDaTabelaDePreco, ItemDaTabelaDePreco } from '@/models'
import { Vue, Component, Ref } from 'vue-property-decorator'
import BuscaDeProdutoDropdown from '@/components/produto/BuscaDeProdutoDropdown.vue'
import BuscaDeItensSemPrecoDropdown from './BuscaDeItensSemPrecoDropdown.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import DatePicker from '@/components/ui/DatePicker.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import { AddItemUseCase, UpdateItemUseCase } from '@/usecases'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { round } from '@/shareds/number-utils'

@Component({
	components: {
		BuscaDeProdutoDropdown,
		CampoDePercentual,
		BuscaDeItensSemPrecoDropdown,
		DatePicker,
	},
})
export default class DialogoDeItemTabelaDePreco extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() buscaDeProduto!: HTMLInputElement
	
	obrigatorio = obrigatorio
	
	mostra = false
	carregando = false

	itemDePreco: ItemDaTabelaDePreco | null = null
	idDaTabelaDePreco: string | null = null
	inicioVigencia: string | null = null
	fimVigencia: string | null = null
	

	addItemUseCase = new AddItemUseCase()
	updateItemUseCase = new UpdateItemUseCase()

	mostrar(idDaTabelaDePreco: string, inicioVigenciaCapa: string, fimVigenciaCapa: string, item?: ItemDaTabelaDePreco) {
		setTimeout(() => {
			this.form.resetValidation()
			this.buscaDeProduto.focus()
		})
		
		this.inicioVigencia = inicioVigenciaCapa
		this.fimVigencia = fimVigenciaCapa
		this.idDaTabelaDePreco = idDaTabelaDePreco
		this.itemDePreco = item
			? JSON.parse(JSON.stringify(item))
			: criarItemDaTabela()
		this.mostra = true
	}

	esconder() {
		this.mostra = false
	}

	calculaPreco() {
		this.calculaPrecoVarejo()
		this.calculaPrecoAtacado()
	}

	calculaPrecoVarejo() {
		if (!this.itemDePreco) return
		const precoCompra = this.itemDePreco.precoCompra || 0
		const margemDeLucro = 1 + (this.itemDePreco.margemLucro || 0) / 100
		
		this.itemDePreco.precoFinalVarejo = round(
			(precoCompra * margemDeLucro ).toString(),
			2,
		)
	}

	calculaPrecoAtacado() {
		if (!this.itemDePreco) return
		this.itemDePreco.precoFinalAtacado = this.itemDePreco.precoFinalVarejo
		this.itemDePreco.descontoAtacado = this.itemDePreco.descontoVarejo
	}

	async salvarItem() {
		if(this.itemDePreco && (this.itemDePreco.percentualCashback > 0 && this.itemDePreco.diasParaEfetuarCashback == 0) 
		) {
			return	AlertModule.setError("O campo 'Dias para efetuar Cashback' não pode estar zerado")
		} else if(this.itemDePreco && (this.itemDePreco.diasParaEfetuarCashback > 0 && this.itemDePreco.percentualCashback == 0)) {
			return	AlertModule.setError("O campo 'Percentual cashback' não pode estar zerado")
		}  else if(this.itemDePreco && (this.itemDePreco.diasParaExpirarCashback > 0 && this.itemDePreco.percentualCashback == 0)) {
			return	AlertModule.setError("O campo 'Dias para expirar Cashback' não pode estar zerado")
		} 
			
		if (!this.form.validate()) return
		if (!this.itemDePreco || !this.idDaTabelaDePreco) return
		this.carregando = true
		
		if(!this.itemDePreco.inicioVigencia || !this.itemDePreco.fimVigencia) {
			this.itemDePreco.inicioVigencia = this.inicioVigencia
			this.itemDePreco.fimVigencia = this.fimVigencia
		}

		if(this.itemDePreco.inicioVigenciaDesconto === '' ) {
			this.itemDePreco.inicioVigenciaDesconto = null
		}
		
		if(this.itemDePreco.fimVigenciaDesconto === '' ) {
			this.itemDePreco.fimVigenciaDesconto = null
		} 
		
		try {
			const itemSalvo = !this.itemDePreco.id
				? await this.addItemUseCase.execute(this.idDaTabelaDePreco, this.itemDePreco)
				: await this.updateItemUseCase.execute(this.idDaTabelaDePreco, this.itemDePreco)
			this.$emit('salvar', itemSalvo)
			AlertModule.setSuccess('Item Salvo')
		} catch (e) {
			AlertModule.setError(e)
		}finally{
			this.carregando = false
		}
	}
}

const criarItemDaTabela = (): FormItemDaTabelaDePreco => {
	return {
		id: '',
		produto: null,
		precoCompra: null,
		margemLucro: null,
		descontoVarejo: null,
		precoFinalVarejo: null,
		descontoAtacado: null,
		precoFinalAtacado: null,
		qtdeMinimaAtacado: null,
		qtdeMaximaAtacado: null,
		inicioVigencia: null,
		fimVigencia: null,
		inicioVigenciaDesconto: null,
		fimVigenciaDesconto: null,
		limitarProduto: false,
		qtdeLimitadaDoProduto: 0,
		percentualCashback: 0,
		diasParaEfetuarCashback: 0,
		skuProdutoPai: null,
		diasParaExpirarCashback: 0,
	}
}
