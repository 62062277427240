
































































import { Vue, Component, PropSync, Ref, Prop, Watch } from 'vue-property-decorator'
import { FindProdutoUseCase } from '@/usecases'
import { Produto, Page } from '@/models'
import { VAutocomplete } from 'vuetify/lib'
import DialogoDeSelecionarVariante from '@/components/produto/DialogoDeSelecionarVariante.vue'
import { Fragment } from 'vue-fragment'
import { displayNomeCompletoDoProduto } from '../../../shareds/produto-shareds'
import { Pageable } from '@/models/Pageable'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import axios, { CancelTokenSource } from 'axios'

@Component({
	components: {
		DialogoDeSelecionarVariante,
		Fragment,
	},
})
export default class BuscaDeItensSemPrecoDropdown extends Vue {
	@Ref() seletorDeVariante!: DialogoDeSelecionarVariante
	@Ref() readonly dropdownSelecaoDeProduto!: typeof VAutocomplete & HTMLInputElement
	@PropSync('value', { type: [Object, String], default: null }) produto!: Produto | null
	@Prop({ type: String, default: '' }) idTabela
	@Prop({ type: Array, default: () => [] }) filtroProdutos!: Produto[]

	displayNomeCompletoDoProduto = displayNomeCompletoDoProduto

	produtos: Produto[] = []
	findProdutoUseCase = new FindProdutoUseCase()
	pagina: Page<Produto> | null = null
	busca: string | null = null

	produtosCarregados = 0
	carregouTodosOsProdutos = false
	carregando = false
	cancelToken: CancelTokenSource | null = null

	focus() {
		this.dropdownSelecaoDeProduto.focus()
	}

	blur() {
		this.dropdownSelecaoDeProduto.blur()
	}

	created() {
		this.buscarItensDaTabelaDePreco()
	}

	async buscarItensDaTabelaDePreco(paginavel: Pageable = {}) {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			this.pagina = await this.findProdutoUseCase.buscaItensPorTabelaDePreco(
				this.idTabela,
				{
					page: this.busca ? -1 : (paginavel.page ? paginavel.page : 0),
					size: paginavel.size,
					busca: this.busca || undefined,
				},
				axiosConfig,
			)
			if (!this.busca) {
				this.pagina.content.forEach(produto => {
					this.produtos.push(produto)
				})
			} else {
				this.produtos = this.pagina.content
			}
			this.produtosCarregados += this.pagina.numberOfElements
			this.carregouTodosOsProdutos = this.produtosCarregados >= this.pagina.totalElements
			this.carregando = false
		} catch (error: any) {
			if (axios.isCancel(error)) return
			this.carregando = false
			AlertModule.setError(error)
		}
	}

	subtituloProduto(produto: Produto) {
		switch (produto.tipo) {
			case 'Com Variantes':
				return `${produto.variantes.length} variante${
					produto.variantes.length > 1 ? 's' : ''
				}`
			case 'Variante': {
				const subtituloVariante = `${[produto.eans[0], produto.sku].filter(valor => !!valor).join('/')}`
				return !subtituloVariante
					? 'Sem código EAN ou SKU cadastrados para esta variante'
					: subtituloVariante
			}
			default:
				return produto.sku
		}
	}

	@Watch('busca')
	onChangeBusca() {
		if (this.busca &&
				this.produto &&
				this.busca === displayNomeCompletoDoProduto(this.produto)) return
		this.buscarItensDaTabelaDePreco()
	}

	selecionarProduto(produto: Produto) {
		produto.tipo === 'Com Variantes'
			? this.seletorDeVariante.abrir(new Promise((r) => r(produto)))
			: this.$emit('input', produto)
	}

	get itens() {
		if (this.carregando) return []
		const produtos = this.filtroProdutos
			? this.produtos.filter(({ id }) => !this.filtroProdutos.some(produto => produto.id === id))
			: this.produtos
		const existe = !!this.produto || produtos.some(({ id }) => id === this.produto?.id)
		return !existe ? produtos : [this.produto, ...produtos]
	}

	get mensagemEmModoDeConsulta() {
		if (!this.busca && !this.carregando) {
			'Digite para consultar...'
		} else if (this.carregando) {
			return 'Carregando...'
		}
		return '$vuetify.noDataText'
	}
}
