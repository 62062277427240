var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.tabela)?_c('v-card',{staticClass:"mx-auto mt-8"},[_c('v-row',[_c('v-col',{staticClass:"d-flex justify-start",attrs:{"cols":"2"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.tabela.nome)+" ")]),_c('v-btn',{staticClass:"mt-10",attrs:{"color":"primary","small":"","fab":"","absolute":"","top":"","font":"","right":""},on:{"click":function () { return _vm.editarCapa(); }}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1),_c('v-col',{attrs:{"cols":"3"}},[(_vm.tabela.tipoDeCliente)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Grupo de Clientes ")]),_c('v-list-item-title',{},[_vm._v(_vm._s(_vm.tabela.tipoDeCliente.nome))])],1)],1):_vm._e()],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Vigência ")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.vigencia))])],1)],1)],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home-group")])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" Grupo Econômico ")]),_c('v-list-item-title',[_vm._v(_vm._s(_vm.tabela.grupoEconomico ? _vm.tabela.grupoEconomico.descricao : '-'))])],1)],1)],1)],1)],1):_vm._e(),_c('v-card',{staticClass:"mt-5"},[[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Lojas Vinculadas ")]),_c('v-expansion-panel-content',[_c('DataTableDeCrud',{attrs:{"items":_vm.lojasFormatadas,"headers":[
								{ text: 'Nome', value: 'nome' } ],"footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50] },"loading":_vm.loading,"hideUpdate":true,"no-data-text":"Não há lojas vinculadas","tituloParaCriar":"Vincular nova loja"},on:{"create":_vm.abrirDialogoDeVincualarLoja,"delete":_vm.aplicarIndiceLojaVinculada}})],1)],1)],1)]],2),_c('v-card',{staticClass:"mt-2"},[_c('v-card-subtitle',[_c('div',[_c('v-row',{staticStyle:{"margin-top":"3px","margin-bottom":"3px"}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Pesquisar...","single-line":"","hide-details":"","loading":_vm.loading},model:{value:(_vm.busca),callback:function ($$v) {_vm.busca=$$v},expression:"busca"}})],1),_c('v-col',[_c('RangeDatePicker',{attrs:{"label":"Data","loading":_vm.loading,"disabled":_vm.loading,"inicial":{
								label: 'Data Inicial',
								clearable: true,
							},"final":{
								label: 'Data Final',
								clearable: true,
							}},model:{value:(_vm.filtro.datas),callback:function ($$v) {_vm.$set(_vm.filtro, "datas", $$v)},expression:"filtro.datas"}})],1),_c('v-col',{staticClass:"mt-5",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"right":""}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v(" A busca é feita utilizando o período final de vigência como referência. ")])])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"mt-5",attrs:{"color":"primary","filled":"","loading":_vm.loading,"disabled":_vm.loading,"small":""},on:{"click":_vm.buscarItensDaTabelaDePreco}},[_c('v-icon',[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1),_c('v-btn',{staticClass:"ml-2 mt-5",attrs:{"color":"primary","right":"","filled":"","loading":_vm.loading,"disabled":_vm.loading,"small":"","max-width":"155"},on:{"click":function () { return _vm.dialogoCsv.mostrar(
										_vm.tabela.id,
										_vm.tabela.nome
									); }}},[_vm._v(" Importar / Exportar ")])],1)],1)],1)]),(_vm.tabela.id)?_c('DataTableDeCrud',{attrs:{"items":_vm.itensTabelaFormatadas,"headers":_vm.headers,"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 15, 30, 50] },"options":_vm.paginacao,"server-items-length":_vm.totalDeRegistros,"tituloParaCriar":"Novo Item"},on:{"update:options":function($event){_vm.paginacao=$event},"create":_vm.abrirDialogo,"update":_vm.abrirDialogo,"delete":_vm.aplicarIndice},scopedSlots:_vm._u([{key:"item.produto.eans",fn:function(ref){
									var item = ref.item;
return [(item.produto.eans.length)?_c('div',[_vm._v(" "+_vm._s(item.produto.eans[0])+" "),(item.produto.eans.length > 1)?_c('v-menu',{attrs:{"offset-x":"","open-on-hover":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
									var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"primary"}},on),[_vm._v(" mdi-dots-horizontal ")])]}}],null,true)},[_c('v-card',[_c('v-container',_vm._l((item.produto.eans),function(ean,indice){return _c('div',{key:indice,staticClass:"blue--text text-decoration-underline",staticStyle:{"cursor":"pointer"},on:{"click":function () { return _vm.copy(ean, {
												onCopy: function () { return _vm.AlertModule.setSuccess(("Copiado: " + ean)); },
											}); }}},[_vm._v(" "+_vm._s(ean)+" ")])}),0)],1)],1):_vm._e()],1):_vm._e()]}}],null,true)}):_vm._e(),_c('DialogoCapaDaTabelaDePreco',{ref:"dialogoEdicaoCapa",on:{"salvar":_vm.salvarCapa}})],1),_c('DialogoDeItemTabelaDePreco',{ref:"dialogo",on:{"salvar":_vm.salvarItemDaTabelaDePrecos}}),_c('DialogoVincularLojaComTabelaDePreco',{ref:"dialogoDeVinculo",on:{"concluirVinculo":_vm.buscarLojaDaTabela}}),_c('Confirmacao',{ref:"confirmacao",attrs:{"titulo":"Remover da tabela de preço","subtitulo":"Tem certeza que deseja remover o produto selecionado?","width":"400"},on:{"confirmar":_vm.removerItem}}),_c('Confirmacao',{ref:"confirmacaoTabelaDeLojas",attrs:{"titulo":"Desvincular loja da tabela de preço","subtitulo":"Tem certeza que deseja desvincular a loja selecionada?","width":"400"},on:{"confirmar":_vm.removerLojaVinculada}}),_c('DialogoDeCsvTabelaDePrecos',{ref:"dialogoCsv",on:{"concluiuImportacao":_vm.buscarItensDaTabelaDePreco}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }